import { render, staticRenderFns } from "./cabinet-main-page.html?vue&type=template&id=23033cf8&scoped=true&"
import script from "./cabinet-main-page.vue?vue&type=script&lang=ts&"
export * from "./cabinet-main-page.vue?vue&type=script&lang=ts&"
import style0 from "./cabinet-create-event.scss?vue&type=style&index=0&id=23033cf8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23033cf8",
  null
  
)

export default component.exports